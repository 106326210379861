/* Global CSS */

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}

#gradient-canvas {
    width:100%;
    height:100%;
    --gradient-color-1: #8f16a7;
    --gradient-color-2: #872222;
    --gradient-color-3: #22c3b8;
    --gradient-color-4: #0a1bff;
    position: fixed;
    z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

:root {
    --primary: #5e3bee;
    --heading-color: white;
    --bg-shade: #f5fcff;
    --github: #e62872;
    --darkblue: #1c1e53;
    --black: black;
    --white: #ffffff;
}

.btn {
    font-family: "Roboto";
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
}
.btn-outline-primary {
    color: var(--primary);
    background-color: var(--white);
    border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
}
.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid black;
}
.btn-primary:hover {
    color: var(--primary);
    background-color: var(--white);
}
.btn-github {
    color: var(--white);
    background-color: var(--github);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
    gap: 16px;
}
.btn-github:hover {
    color: var(--github);
    background-color: var(--white);
}
/* Global CSS End */


h1 {
    font-size: 56px;
    font-weight: 700;
    line-height: 67px;
}
h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
}
h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
}

/* Paragraphs */

.text-lg {
    color: var(--darkblue);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}
.text-md {
    color: var(--darkblue);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.text-sm {
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

/* Navbar CSS */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 85px;
    background: var(--white);
    box-shadow: 0px 5px 80px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.nav__logo__text {
    font-size: 35px;
    margin-left: 18px;
    font-weight: 800;
}
.nav__logo__container {
    display: flex;
    align-items: center;
}
.navbar--items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 42.667px;
    text-decoration: none;
}
.navbar--items ul > li > a {
    text-decoration: none;
}
.navbar--content {
    color: var(--darkblue);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}
.navbar--active-content {
    color: var(--primary);
}
/* Titles */

.section--title {
    color: var(--heading-color);
    font-size: 88px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
}

/* Portfolio */

.sub--title {
    color: var(--heading-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}


.hero--section {
    padding: 133px 85px 133px;
}
.hero--section--content--box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

}
.hero--section--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
}
.hero--section--description {
    color: white;
    font-size: 20px;
    text-align: center;
}
.hero--section--content--box > button {
    margin-top: 21px;
}
.hero--section--title {
    color: var(--heading-color);
    font-size: 30px;
    font-weight: 700;
    line-height: 90px;
    align-self: stretch;
    text-align: center;
}
.hero--section--title--color {
    color: var(--primary);
}
.hero--section--title-description {
    color: var(--white);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
}
/*.hero--section--img {*/
/*    display: flex;*/
/*}*/
/*.hero--section--img > img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/* Hero Section CSS END */

/* About Me CSS */

.about--section {
    padding: 133px 85px;
    align-items: center;
    gap: 114px;
    grid-template-columns: repeat(2, 1fr);
}
.about--section--img > img {
    width: 100%;
    height: 100%;
}
.skills-section--heading {
    color: white;
}

/* My Portfolio CSS */
.portfolio--section {
    display: flex;
    padding: 112px 85px;
    flex-direction: column;
    align-items: flex-start;
    gap: 88px;
}
.portfolio--container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.portfolio--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
}
.portfolio--section--container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 42px;
    width: 100%;
}
.portfolio--section--img {
    border-radius: 8px;
    width: 100%;
}
.portfolio--section--img > img {
    width: 100%;
}
.portfolio--section--card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0.15);
    cursor: pointer;
    border-top-left-radius: 12px;
}

.portfolio--section--contact-card {
    margin: 0px 85px 20px 85px;
}

.portfolio--section--contact-card--spacer {
    flex-grow: 1;
}

.portfolio--section--contact-card .portfolio--section--card {
    flex-grow: 1;
    width: 100%;
    cursor: unset;
}

.portfolio--section--card--content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}
.portfolio--section--title {
    color: var(--primary);

}
.portfolio--link {
    text-decoration: none;
    display: flex;
    gap: 16px;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 10px;
    font-weight: 600;
}
.portfolio--section--card:hover path {
    stroke: #006b6a;
}

/* Github Logo CSS */

.btn-github > svg {
    height: 50px;
    width: 50px;
}


.section--heading {
    color: white;
}

/* Footer CSS */

.footer--container {
    display: flex;
    padding: 106px 85px;
    flex-direction: column;
    align-items: flex-start;
    background: var(--bg-shade);
    align-items: center;
    align-self: stretch;
}
.footer--link--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.footer__logo__text {
    font-size: 35px;
    margin-left: 18px;
    font-weight: 800;
}
.footer__logo__container {
    display: flex;
    align-items: center;
}
.footer--items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 42px;
    text-decoration: none;
}
.footer--items ul > li > a {
    text-decoration: none;
    cursor: pointer;
}
.footer--social--icon > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    text-decoration: none;
}
.divider {
    margin: 106px 0 42px;
    height: 1px;
    width: 100%;
    background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.footer--content {
    color: var(--black);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

/* Contact Me CSS */

.contact--section {
    display: flex;
    color: white;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.contactMe--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    background-color: white;
    padding: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: black;
}
.contact--info--container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 50px;
    background-color: white;
    padding: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.contactMe--tel {
    display: flex;
    align-items: flex-end;
    padding: 5px;
}
.contactMe--mail {
    display: flex;
    align-items: flex-end;
    padding: 5px;
}

.contact--info--text {
   display: flex;
}
.contact--info {
    margin-left: 28px;
}
.contact--mail {
    margin-left: 10px;
}
/* Responsive Screens */
@media only screen and (max-width: 1800px) {
    .hero--section--title {
        font-size: 68px;
        line-height: 70px;
    }
    .skills--section--title {
        font-size: 28px;
        line-height: 40px;
    }
}

/*@media only screen and (max-width: 1600px) {*/
/*    .skills--section--heading {*/
/*        font-size: 54px;*/
/*        line-height: 70px;*/
/*    }*/
/*    .skills--section--container {*/
/*        gap: 16px;*/
/*    }*/
/*    .skills--section--card {*/
/*        gap: 28px;*/
/*    }*/
/*    .skills--section--card--content {*/
/*        gap: 20px;*/
/*    }*/
/*    .skills--section--title {*/
/*        font-size: 23px;*/
/*        line-height: 30px;*/
/*    }*/
/*    .skills--section--description {*/
/*        font-size: 17.333px;*/
/*        line-height: 27px;*/
/*    }*/
/*}*/

@media only screen and (max-width: 1200px) {
    .btn-outline-primary {
        display: none;
    }
    .hero--section {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 70px;
    }
    .hero--section--title,
    .hero--section--description,
    .footer--content {
        text-align: center;
    }
    .skills--section--container,
    .about--section,
    .portfolio--section--container,
    .portfolio--container-box,
    .contact--section,
    .contactMe--container,
    .contact--info--text,
    .contact--info--container,
    .footer--link--container,
    .footer--items > ul,
    .footer--content--container {
        display: flex;
        flex-direction: column;
    }
    .skills--section,
    .portfolio--container,
    .hero--section--content,
    .hero--section--content--box {
        align-items: center;
    }
    .portfolio--container-box {
        gap: 30px;
    }
    .portfolio--section {
        margin: 1px;
    }
    /*.portfolio--section--card--content {*/
    /*    margin:*/
    /*}*/
    .skills--section,
    .testimonial--section,
    .contact--section,
    .footer--container {
        gap: 20px;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .about--section {
        gap: 20px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .testimonial--section--card {
        padding: 25px;
    }
    .section--heading {
        color: white;
    }
    .contactMe--tel,
    .contactMe--mail,
    .contact--info,
    .contact--mail {
        /*font-size: 28px;*/
    }
    .hero--section--title,
    .skills--section--heading,
    .sections--heading {
        font-size: 35px;
        line-height: 40px;
    }
    .hero--section--content--box {
        gap: 10px;
    }
    .contact--form--container {
        width: 100%;
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .footer--social--icon > ul,
    .footer--items > ul {
        padding: 0;
        align-items: center;
    }
    .divider {
        margin: 20px;
    }
}

/* Hamburger menu  */

.nav__hamburger {
    display: none;
    width: 1.875rem;
    height: 1.313rem;
    flex-direction: column;
    justify-content: space-around;
}

.nav__hamburger__line {
    display: block;
    height: 0.188rem;
    width: 100%;
    background-color: #000000;
    border-radius: 0.625rem;
    transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 10px 38px;
    }

    .portfolio--section {
        padding: 112px 38px;
    }

    .portfolio--section--contact-card {
        margin: 5px 38px;
    }

    .hero--section {
        padding: 133px 38px 133px;
    }

    .about--section {
        padding: 133px 38px;
    }

    .footer--container {
        padding: 106px 38px;
    }
}

@media screen and (max-width: 1200px) {
    .nav__hamburger {
        display: flex;
        z-index: 200;
    }
    .navbar--items {
        display: flex;
    }
    .navbar--items {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255);
        top: -20rem;
        left: 0;
        width: 100%;
        transition: all ease-in-out 0.4s;
    }
    .navbar--items ul {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 3rem 0 0.6rem;
        z-index: -100;
    }
    .navbar--items ul li {
        text-align: center;
    }
    .navbar--items ul li a {
        padding: 0.5rem;
    }
    .navbar--items {
        z-index: -1000;
    }
    .navbar--items.active {
        top: 30px;
    }
    .nav__hamburger.active :nth-child(1) {
        transform: rotate(45deg) translate(0.45rem, 0.1875rem);
    }

    .nav__hamburger.active :nth-child(2) {
        opacity: 0;
    }

    .nav__hamburger.active :nth-child(3) {
        transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
    }
}
